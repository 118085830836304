//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseCmsBlock from "../../node_modules/sitis-nuxt/lib/cms/CmsBlockMixin";
import SlideItem from "~/components/special-block/SlideItem.vue";

export default BaseCmsBlock.extend({
  name: "SpecialBlock",

  data: function () {
    return {
      activeSlide: 0,
      timeOutAutoplay: null
    }
  },

  computed: {
    slides: function () {
      return [...(this.extras?.items || [])];
    },
    pagination: function () {
      return [...(this.slides || [])].length
    },

    isVisible: function () {
      if (!Boolean(this.values?.visible || '')) {
        return true
      }

      const isMobile = Boolean(this.$store.state["local-settings"]?.viewport === 'mobile');
      if (this.values?.visible === 'pc' && !isMobile) {
        return true
      }
      if (this.values?.visible === 'mobile' && isMobile) {
        return true
      }

      return false;
    },
  },

  components: {
    SlideItem
  },

  mounted: function () {
    this.startAutoplay();
  },
  destroyed: function () {
    clearTimeout(this.timeOutAutoplay)
  },

  methods: {
    changeActiveSlide: function (newActiveSlide) {
      clearTimeout(this.timeOutAutoplay)
      if (newActiveSlide > (this.slides.length - 1)) {
        newActiveSlide = 0
      }
      if (newActiveSlide < 0) {
        newActiveSlide = (this.slides.length - 1)
      }
      this.activeSlide = newActiveSlide;
      this.startAutoplay();
    },

    startAutoplay: function () {
      const intervalSeconds = this.values?.interval_seconds || 10;
      this.timeOutAutoplay = setTimeout(() => {
        this.changeActiveSlide(this.activeSlide + 1);
      }, (intervalSeconds * 1000));
    },
  }
});
